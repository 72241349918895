<template>
  <div id="warehouse-setting">
    <v-data-table
      :headers="header"
      :items="warehouseData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      height="80vh"
      class="elevation-1 mb-5 draggable-table"
      hide-default-footer
      @page-count="pageCount = $event">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Warehouse
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            width="110px"
            @click="dialogToggle()">
            New Item
          </v-btn>
          <v-btn
            color="success"
            class="mb-2 ml-2"
            width="110px"
            :disabled="disableSaveWarehouse"
            @click="savePriorityWarehouse()">
            save
          </v-btn>
          <v-dialog
            v-model="dialog"
            max-width="600px">
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.code"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Code" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.name"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Name" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.name2"
                          :readonly="modalLoading"
                          label="Name 2" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.branch"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Branch" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-textarea
                          v-model="formData.address"
                          :readonly="modalLoading"
                          :rules="textFieldRules"
                          label="Address" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-textarea
                          v-model="formData.address2"
                          :readonly="modalLoading"
                          label="Address 2" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.taxId"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Tax Id" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-text-field
                          v-model="formData.subDistrict"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Sub District" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-text-field
                          v-model="formData.district"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="District" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-text-field
                          v-model="formData.stateProvince"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Province" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-text-field
                          v-model="formData.postcode"
                          :rules="numberFieldRules"
                          :readonly="modalLoading"
                          label="Post Code" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-text-field
                          v-model="formData.country"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Country" />
                      </v-col>
                      <v-col :cols="6">
                        <gw-select
                          v-model="formData.type"
                          :rules="textFieldRules"
                          :items="warehouseType"
                          :readonly="modalLoading"
                          :menu-props="{ offsetY: true }"
                          label="Type"
                          item-text="name" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #body="{ headers }">
        <draggable
          :list="warehouseData"
          group="warehouses"
          tag="tbody">
          <tr
            v-for="(item, index) in warehouseData"
            :key="`warehouse-index-${index}`">
            <td :width="headers[0].width">
              <v-switch
                v-model="warehouseData[index].status"
                dense
                true-value="active"
                false-value="inactive" />
            </td>
            <td
              class="text-start vertical-top pa-4"
              :width="headers[1].width">
              {{ item.id }}
            </td>
            <td
              class="text-start vertical-top pa-4"
              :width="headers[1].width">
              {{ item.code }}
            </td>
            <td
              class="text-start vertical-top pa-4"
              :width="headers[3].width"
              style="text-wrap: nowrap;">
              {{ item.name }}
            </td>
            <td
              class="text-end vertical-top pa-4"
              :width="headers[4].width">
              <v-icon
                v-if="isUsersEditable"
                small
                @click="editUsers(item)">
                mdi-account-edit
              </v-icon>
              <v-icon
                class="mx-2"
                small
                @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="removeWarehouse(item)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <UserList
      v-model="userDialog"
      :warehouse="selectedWarehouse" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import WarehouseProvider from '@/resources/WarehouseProvider'
import { getRole } from '@/assets/js/Authentication'
import UserList from '../components/UserList.vue'

const WarehouseService = new WarehouseProvider()

export default {
  components: {
    Draggable,
    UserList
  },
  data () {
    return {
      dialog: false,
      userDialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 9999,
        sortBy: ['priority'],
        sortDesc: [false]
      },
      header: [
        {
          text: 'Active',
          value: 'active',
          width: 60,
          sortable: false
        },
        {
          text: 'ID',
          value: 'id',
          sortable: false
        },
        {
          text: 'Code',
          value: 'code',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          width: 150,
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'end'
        }
      ],
      warehouseData: [],
      memoWarehouses: [],
      isEdited: false,
      formData: {
        type: 'store',
        priority: 99999
      },
      textFieldRules: {
        required: true
      },
      numberFieldRules: {
        required: true,
        regex: /^[0-9]+$/
      },
      getting: false,
      modalLoading: false,
      isFocus: false,
      warehouseType: [
        {
          name: 'Store',
          value: 'store'
        },
        {
          name: 'Warehouse',
          value: 'warehouse'
        },
        {
          name: 'Virtual Warehose',
          value: 'virtual_warehose'
        }
      ],
      selectedWarehouse: null
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Warehouse' : 'New Warehouse'
    },
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    paginationEnable () {
      return this.pageCount > 1
    },
    disableSaveWarehouse () {
      return JSON.stringify(this.memoWarehouses) === JSON.stringify(this.warehouseData) || this.getting
    },
    isUsersEditable () {
      const role = getRole()
      const roles = [
        'management',
        'area_manager',
        'developer',
        'accounting_manager',
        'support'
      ]

      return roles.includes(role)
    }
  },
  created () {
    this.getWarehouse()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal',
      setWarehouse: 'Store/setWarehouse'
    }),
    setPageCount (event) {
      this.pageCount = event
    },
    async getWarehouse () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await WarehouseService.getWarehouse()
          this.warehouseData = data
          this.memoWarehouses = new Array(...JSON.parse(JSON.stringify(data)))
          this.setWarehouse(this.warehouseData)
        } catch (error) {
          console.error('getWarehouse: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    dialogToggle () {
      this.dialog = true
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {
        type: 'store',
        priority: 99999
      }
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        code: item.code,
        name: item.name,
        name2: item.name2,
        branch: item.branch,
        address: item.address,
        address2: item.address2,
        subDistrict: item.subDistrict,
        district: item.district,
        stateProvince: item.stateProvince,
        postcode: item.postcode,
        type: item.type,
        country: item.country,
        taxId: item.taxId
      }
      this.dialog = true
    },
    editUsers (item) {
      this.selectedWarehouse = item
      this.userDialog = true
    },
    onCloseUserDialog () {
      this.selectedWarehouse = null
      this.userDialog = false
    },
    mapForMemosPriorityAndStatus (warehouses) {
      return warehouses.map((warehouse) => ({
        id: warehouse.id,
        priority: warehouse.priority,
        status: warehouse.status
      }))
    },
    mapForEditPriorityAndStatus (warehouses) {
      return warehouses.map((warehouse, index) => ({
        id: warehouse.id,
        priority: index + 1,
        status: warehouse.status
      }))
    },
    async savePriorityWarehouse () {
      try {
        this.getting = true
        this.loading = true

        const unpromised = []
        const memos = this.mapForMemosPriorityAndStatus(this.memoWarehouses)
        const warehouses = this.mapForEditPriorityAndStatus(this.warehouseData)
        const statusPayloads = []
        const priorityPayloads = []

        for (const memo of memos) {
          const warehouse = warehouses.find((w) => w.id === memo.id)

          if (warehouse.id) {
            if (warehouse.status !== memo.status) {
              statusPayloads.push(warehouse)
            }

            if (warehouse.priority !== memo.priority) {
              priorityPayloads.push(warehouse)
            }
          }
        }

        if (statusPayloads.length) {
          unpromised.push(WarehouseService.updateStatus({ payloads: statusPayloads }))
        }

        if (priorityPayloads.length) {
          unpromised.push(WarehouseService.updatePriority({ payloads: priorityPayloads }))
        }

        await Promise.all(unpromised)
        this.memoWarehouses = new Array(...JSON.parse(JSON.stringify(this.warehouseData)))
      } catch (error) {
        console.error('Save Warehouse: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
        this.getting = false
      }
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          const form = this.formData
          if (this.isEdited) {
            isSuscess = await this.updateWarehouse(form)
            message = 'updated'
          } else {
            isSuscess = await this.createWarehouse(form)
          }
          this.closeFormModal()
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.Warehouse ${message}`),
              type: 'success'
            })
            this.getWarehouse()
          }
        }
      }
      this.modalLoading = false
    },
    async createWarehouse (item) {
      const { data } = await WarehouseService.createWarehouse(item)
      return !!data.id
    },
    async updateWarehouse (item) {
      const { data } = await WarehouseService.updateWarehouse(item.id, item)
      return !!data.id
    },
    async removeWarehouse (item) {
      this.setModal({
        value: true,
        title: 'RemoveWarehouse',
        message: 'Do you want to remove the Warehouse',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveWarehouse(item.id)
      })
    },
    async confirmRemoveWarehouse (id) {
      try {
        const { data } = await WarehouseService.deleteWarehouse(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.Warehouse removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.warehouseData.length < 2) {
            this.options.page--
          } else {
            this.getWarehouse()
          }
        } else {
          const error = {
            code: 400,
            message: 'Warehouse ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveWarehouse', error)
      }
    }
  }
}
</script>

<style scoped>
.vertical-top {
  vertical-align: top;
}

.theme--light.v-data-table.draggable-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12);
}
</style>
